
import constentModule from '@/components/consentModule';
export default {
    components: {

        constentModule
    },
    data() {
        return {
            conditionalAccept: false,
            adStorage: false,
            analytics: false,
            scrolled: false,
            cookiesSelected: false,
            hideBanner: true,
        }
    },
    mounted() {

let checkCookie = document.cookie.indexOf("consent=checkmot");
this.hideBanner = checkCookie !== -1 ? true : false ;
        this.mallConsentGranted();
    },
    methods: {
        setCookie(){
            let d = new Date();
        d.setTime(d.getTime() + 10 * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = "consent=checkmot;" + expires + ";path=/";
        },
        checkCookieSelected(){
            let checkCookie = document.cookie.indexOf("consent=checkmot");
            this.cookiesSelected = checkCookie !== -1 ? true : false ;
            if(this.cookiesSelected )
            this.conditionalAccept = true;
        },
        allConsentGranted() {
            this.appendScript()
        },
        appendScript() {
            const script = document.createElement('script')
            script.text = this.getSctipt();
            document.getElementsByTagName('head')[0].appendChild(script)
            this.setCookie()
        },
        getSctipt() {
            return `gtag('consent', 'update', {
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'ad_storage': 'granted',
      'analytics_storage': 'granted'
    });`;
        },

        mallConsentGranted() {
            this.mappendScript()
        },
        mappendScript() {
            const script = document.createElement('script')
            script.text = this.mgetSctipt();
            document.getElementsByTagName('head')[0].appendChild(script)
        },
        mgetSctipt() {
            return `gtag('consent', 'default', {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied'
});`;
        },
        rejectallConsentGranted() {
            this.rejectappendScript()
        },
        rejectappendScript() {
            const script = document.createElement('script')
            script.text = this.rejectgetSctipt();
            document.getElementsByTagName('head')[0].appendChild(script)
            this.setCookie()
        },
        rejectgetSctipt() {
            return `gtag('consent', 'update', {
  'ad_storage': 'denied',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage': 'denied'
});`;
        },

        acceptConsentGranted() {
            this.acceptappendScript()
        },
        acceptappendScript() {
            const script = document.createElement('script')
            script.text = this.acceptgetSctipt();
            document.getElementsByTagName('head')[0].appendChild(script)
            this.setCookie()
        },
        acceptgetSctipt() {
            return `gtag('consent', 'update', {
  'ad_storage': '${this.adStorage ? 'granted' : 'denied'}',
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
  'analytics_storage':  '${this.analytics ? 'granted' : 'denied'}'
});`;
        },

    },

}
