
import RegistrationComponent from '@/components/modules/RegistrationComponent';
export default {
  components: {
    RegistrationComponent
  },
  data() {
    return {
      usefulLinks: [],
      showLinks: false,
      linksLoading: true,
    }
  },
  transition: {
    name: 'home',
    mode: 'out-in'
  },
  methods: {
    async fetchUsefulLinks(val) {
      //Get the list of post data
      this.usefulLinks = await fetch(this.$config.fetchUrl + '/api/info/getUsefulLinks')
        .then(res => res.json()).catch(error => {
          console.error("There was an error!", error);
        });
      this.linksLoading = false;
      this.$nextTick(() => {
        this.scrollToBottom(val);
      });
    },
    scrollBottom(val) {
      this.showLinks = true;
      this.$nextTick(() => {
        this.fetchUsefulLinks(val);
      });
    }
  }
}
