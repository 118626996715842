export const SectionLoader = () => import('../../components/SectionLoader.vue' /* webpackChunkName: "components/section-loader" */).then(c => wrapFunctional(c.default || c))
export const ConsentModule = () => import('../../components/consentModule.vue' /* webpackChunkName: "components/consent-module" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const LoaderComponent = () => import('../../components/loaderComponent.vue' /* webpackChunkName: "components/loader-component" */).then(c => wrapFunctional(c.default || c))
export const CoreFooter = () => import('../../components/core/Footer.vue' /* webpackChunkName: "components/core-footer" */).then(c => wrapFunctional(c.default || c))
export const CoreFooterC = () => import('../../components/core/FooterC.vue' /* webpackChunkName: "components/core-footer-c" */).then(c => wrapFunctional(c.default || c))
export const CoreHeader = () => import('../../components/core/Header.vue' /* webpackChunkName: "components/core-header" */).then(c => wrapFunctional(c.default || c))
export const ModulesBookMotForm = () => import('../../components/modules/BookMotForm.vue' /* webpackChunkName: "components/modules-book-mot-form" */).then(c => wrapFunctional(c.default || c))
export const ModulesBookMotFormCity = () => import('../../components/modules/BookMotFormCity.vue' /* webpackChunkName: "components/modules-book-mot-form-city" */).then(c => wrapFunctional(c.default || c))
export const ModulesClaimContactForm = () => import('../../components/modules/ClaimContactForm.vue' /* webpackChunkName: "components/modules-claim-contact-form" */).then(c => wrapFunctional(c.default || c))
export const ModulesHeroArea = () => import('../../components/modules/HeroArea.vue' /* webpackChunkName: "components/modules-hero-area" */).then(c => wrapFunctional(c.default || c))
export const ModulesHeroAreaHome = () => import('../../components/modules/HeroAreaHome.vue' /* webpackChunkName: "components/modules-hero-area-home" */).then(c => wrapFunctional(c.default || c))
export const ModulesHeroAreaInternal = () => import('../../components/modules/HeroAreaInternal.vue' /* webpackChunkName: "components/modules-hero-area-internal" */).then(c => wrapFunctional(c.default || c))
export const ModulesHeroPrime = () => import('../../components/modules/HeroPrime.vue' /* webpackChunkName: "components/modules-hero-prime" */).then(c => wrapFunctional(c.default || c))
export const ModulesMotorway = () => import('../../components/modules/Motorway.vue' /* webpackChunkName: "components/modules-motorway" */).then(c => wrapFunctional(c.default || c))
export const ModulesPostcodeComponent = () => import('../../components/modules/PostcodeComponent.vue' /* webpackChunkName: "components/modules-postcode-component" */).then(c => wrapFunctional(c.default || c))
export const ModulesRegistrationComponent = () => import('../../components/modules/RegistrationComponent.vue' /* webpackChunkName: "components/modules-registration-component" */).then(c => wrapFunctional(c.default || c))
export const ModulesRegistrationComponentNI = () => import('../../components/modules/RegistrationComponentNI.vue' /* webpackChunkName: "components/modules-registration-component-n-i" */).then(c => wrapFunctional(c.default || c))
export const ModulesSellCarForm = () => import('../../components/modules/SellCarForm.vue' /* webpackChunkName: "components/modules-sell-car-form" */).then(c => wrapFunctional(c.default || c))
export const ModulesSellMyCarHeroArea = () => import('../../components/modules/SellMyCarHeroArea.vue' /* webpackChunkName: "components/modules-sell-my-car-hero-area" */).then(c => wrapFunctional(c.default || c))
export const ModulesStationCardList = () => import('../../components/modules/StationCardList.vue' /* webpackChunkName: "components/modules-station-card-list" */).then(c => wrapFunctional(c.default || c))
export const CoreSideBarMenu = () => import('../../components/core/SideBar/SideBarMenu.vue' /* webpackChunkName: "components/core-side-bar-menu" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
