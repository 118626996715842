export default () => {
  const script = document.createElement('script')
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-R4P4YZ9VXR'
  script.text = ``
  script.async = true
  document.getElementsByTagName('head')[0].appendChild(script);

  const script2 = document.createElement('script') 
  script2.text = `window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'G-R4P4YZ9VXR');`
  document.getElementsByTagName('head')[0].appendChild(script2);
  const script3 = document.createElement('script') 
  script3.text = `gtag('consent', 'default', {
'ad_storage': 'denied',
'ad_user_data': 'denied',
'ad_personalization': 'denied',
'analytics_storage': 'denied'
});`
  document.getElementsByTagName('head')[0].appendChild(script3);
};
